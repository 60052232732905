<template>
  <div class="box">
    <div class="nav"></div>
    <div class="information">
      <div class="jbxx">
        <div class="title">基本信息</div>
        <div style="margin: 20px"></div>
        <el-form :label-position="labelPosition" label-width="170px" :model="formLabelAlign" :inline="true" :rules="rules"
          ref="formLabelAlign" :hide-required-asterisk="true">
          <el-form-item label="血型:" style="width: 49%">
            <el-select v-model="formLabelAlign.bloodType" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="RH血型:" style="width: 49%">
            <el-select v-model="formLabelAlign.rhBloodType" placeholder="请选择">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="药物过敏史:" prop="name" style="width: 49%">
            <el-select v-model="formLabelAlign.drugAllergy" placeholder="请选择">
              <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他过敏药物:" prop="name" style="width: 49%">
            <el-input v-model="formLabelAlign.otherDrugAllergy"></el-input>
          </el-form-item>
          <el-form-item label="健康危险因素暴露史:" prop="name" style="width: 49%">
            <el-input v-model="formLabelAlign.exposureHistory"></el-input>
          </el-form-item>
          <el-form-item label="疾病史:" style="width: 49%">
            <el-select v-model="formLabelAlign.diseasesHistory" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手术史:" style="width: 49%">
            <el-select v-model="formLabelAlign.operationHistory" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="外伤史:" style="width: 49%">
            <el-select v-model="formLabelAlign.rtaumaHistory" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输血史:" style="width: 49%">
            <el-select v-model="formLabelAlign.bloodTransfusion" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="家族史:" style="width: 49%">
            <el-select v-model="formLabelAlign.familyHistory" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="遗传史:" style="width: 49%">
            <el-select v-model="formLabelAlign.geneticHistory" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="残疾情况:" prop="name" style="width: 49%">
            <el-input v-model="formLabelAlign.disabilitySituation"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="jkzd">
        <div class="title">健康指导</div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline" :label-position="labelPosition"
          label-width="170px">
          <el-form-item label="饮食指导:" prop="name" style="width: 100%">
            <el-input type="textarea" :rows="5" placeholder="请根据老人饮食方面的信息" v-model="formLabelAlign.dietGuide"
              style="width: 88rem">
            </el-input>
          </el-form-item>
          <el-form-item label="其他综合指导:" prop="name" style="width: 100%">
            <el-input type="textarea" :rows="5" placeholder="请根据老人健康模型，维护生活习惯、康复理疗 、注意事项等信息"
              v-model="formLabelAlign.otherGuide" style="width: 88rem">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="grjws" style="height: 30rem">
        <div class="title">个人既往史</div>
        <div>
          <el-button type="primary" icon="el-icon-circle-plus">新增</el-button>
          <div class="tableBox">
            <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
              :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
              :isNumber="table.isNumber" :formSearch="query">
              <div slot-scope="selected" slot="EditColumn">
                <el-button icon="el-icon-delete" type="text" size="small"
                  @click="delete (selected.singlerow)">删除</el-button>
              </div>
            </hltableZ>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
export default {
  name: "YanglaoJkda",
  components: { hltableZ },
  props: {
    dataProps: {
      type: Object,
      default: ''
    }

  },
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        bloodType: '',
        rhBloodType: '',
        drugAllergy: '',
        otherDrugAllergy: '',
        diseasesHistory: '',
        operationHistory: '',
        rtaumaHistory: '',
        dietGuide: '',
        bloodTransfusion: '',
        familyHistory: '',
        geneticHistory: '',
        disabilitySituation: '',
        dietGuid: '',
        otherGuide: '',
        userId: '',
        id: '',
      },
      formInline: {
        user: "",
        region: "",
      },
      rules: {

        idCard: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
      },
      options: [
        {
          value: "A型",
          label: "A型",
        },
        {
          value: "B型",
          label: "B型",
        },
        {
          value: "O型",
          label: "O型",
        },
        {
          value: "AB型",
          label: "AB型",
        },
        {
          value: "不详",
          label: "不详",
        },
      ],
      options2: [
        {
          value: "阴性",
          label: "阴性",
        },
        {
          value: "阳性",
          label: "阳性",
        },
        {
          value: "不详",
          label: "不详",
        },
      ],
      options3: [
        {
          value: "青霉素",
          label: "青霉素",
        },
        {
          value: "其他",
          label: "其他",
        },
        {
          value: "无",
          label: "无",
        },
      ],
      options4: [
        {
          value: "无",
          label: "无",
        },
        {
          value: "有",
          label: "有",
        },
      ],
      query: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table: {
        columnEdit: true,
        column: [
          {
            label: "既往史类型",
            prop: "oldName",
            checked: true,
          },
          {
            label: "名称/原因",
            prop: "2",
            checked: true,
          },
          {
            label: "发生时间",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
    };
  },

  mounted() {
    this.ondeld()
  },

  methods: {
    delete(e) {
      console.log(e);
    },
    ondeld() {
      Object.keys(this.formLabelAlign).forEach((key) => {
        this.formLabelAlign[key] = this.dataProps.result.healthRecordEntity[key] || this.formLabelAlign[key]
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.box {


  .container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.jbxx .jkzd .grjws {
  width: 100%;
}

.grjws {
  button {
    margin: 1rem;
  }

  .tableBox {
    height: 30rem;
    width: 100%;
  }
}

// 所有的蓝色标签
.title {
  width: 98%;
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin: 1rem auto;
  font-size: 1rem;
  color: rgb(50, 50, 255);
}

.el-input {
  width: 30rem;
}

.el-select {
  width: 30rem;
}

.el-checkbox {
  margin-right: 2px;
}

.hobby {
  width: 100%;
  border: 1px solid #d5d5d5;
  padding: 0 5px;
}
</style>